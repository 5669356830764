
import { mbtiFeedback } from "./mbti-feedback"
import { personalityQuestions } from "./personality-questions"

export function getPersonalityStrings() {
    return {
        en: {
            pagePrevText: "Previous",
            pageNextText: "Next",
            completeText: "Complete",

            mbti: "What is your MBTI type?",
            mbtiDescription:
                "If you don't know your MBTI type, you can select 'I don't know'.",
            iDontKnow: "I don't know",
            mbtiRequired: "Please select your MBTI type.",

            requiredErrorText: "Please select an option.",
            reportIntro: "Every personality type has qualities that make it uniquely suited to building a successful business. Let’s explore your strengths and how you can grow to achieve your dreams.",

            INTP: mbtiFeedback.INTP.en,
            ENTP: mbtiFeedback.ENTP.en,
            INFJ: mbtiFeedback.INFJ.en,
            ENFJ: mbtiFeedback.ENFJ.en,
            ISTJ: mbtiFeedback.ISTJ.en,
            ESTJ: mbtiFeedback.ESTJ.en,
            ISFJ: mbtiFeedback.ISFJ.en,
            ESFJ: mbtiFeedback.ESFJ.en,
            INTJ: mbtiFeedback.INTJ.en,
            ENTJ: mbtiFeedback.ENTJ.en,
            INFP: mbtiFeedback.INFP.en,
            ENFP: mbtiFeedback.ENFP.en,
            ISTP: mbtiFeedback.ISTP.en,
            ESTP: mbtiFeedback.ESTP.en,
            ISFP: mbtiFeedback.ISFP.en,
            ESFP: mbtiFeedback.ESFP.en,

            q1: personalityQuestions[0].en,
            q1a1: personalityQuestions[0].options.en[0],
            q1a2: personalityQuestions[0].options.en[1],
            q1a3: personalityQuestions[0].options.en[2],
            q1a4: personalityQuestions[0].options.en[3],
            q1f1: personalityQuestions[0].feedback.en[0],
            q1f2: personalityQuestions[0].feedback.en[1],
            q1f3: personalityQuestions[0].feedback.en[2],
            q1f4: personalityQuestions[0].feedback.en[3],
            q2: personalityQuestions[1].en,
            q2a1: personalityQuestions[1].options.en[0],
            q2a2: personalityQuestions[1].options.en[1],
            q2a3: personalityQuestions[1].options.en[2],
            q2a4: personalityQuestions[1].options.en[3],
            q2f1: personalityQuestions[1].feedback.en[0],
            q2f2: personalityQuestions[1].feedback.en[1],
            q2f3: personalityQuestions[1].feedback.en[2],
            q2f4: personalityQuestions[1].feedback.en[3],
            q3: personalityQuestions[2].en,
            q3a1: personalityQuestions[2].options.en[0],
            q3a2: personalityQuestions[2].options.en[1],
            q3a3: personalityQuestions[2].options.en[2],
            q3a4: personalityQuestions[2].options.en[3],
            q3f1: personalityQuestions[2].feedback.en[0],
            q3f2: personalityQuestions[2].feedback.en[1],
            q3f3: personalityQuestions[2].feedback.en[2],
            q3f4: personalityQuestions[2].feedback.en[3],
            q4: personalityQuestions[3].en,
            q4d: personalityQuestions[3].description.en,
            q4a1: personalityQuestions[3].options.en[0],
            q4a2: personalityQuestions[3].options.en[1],
            q4a3: personalityQuestions[3].options.en[2],
            q4a4: personalityQuestions[3].options.en[3],
            q4f1: personalityQuestions[3].feedback.en[0],
            q4f2: personalityQuestions[3].feedback.en[1],
            q4f3: personalityQuestions[3].feedback.en[2],
            q4f4: personalityQuestions[3].feedback.en[3],
            q5: personalityQuestions[4].en,
            q5a1: personalityQuestions[4].options.en[0],
            q5a2: personalityQuestions[4].options.en[1],
            q5a3: personalityQuestions[4].options.en[2],
            q5a4: personalityQuestions[4].options.en[3],
            q5f1: personalityQuestions[4].feedback.en[0],
            q5f2: personalityQuestions[4].feedback.en[1],
            q5f3: personalityQuestions[4].feedback.en[2],
            q5f4: personalityQuestions[4].feedback.en[3],
            q6: personalityQuestions[5].en,
            q6a1: personalityQuestions[5].options.en[0],
            q6a2: personalityQuestions[5].options.en[1],
            q6a3: personalityQuestions[5].options.en[2],
            q6a4: personalityQuestions[5].options.en[3],
            q6f1: personalityQuestions[5].feedback[0],
            q6f2: personalityQuestions[5].feedback.en[1],
            q6f3: personalityQuestions[5].feedback.en[2],
            q6f4: personalityQuestions[5].feedback.en[3],
        },
        ko: {
            pagePrevText: "이전",
            pageNextText: "다음",
            completeText: "결과 보러가기",
            mbti: "당신의 MBTI는 무엇인가요?",
            mbtiDescription:
                "MBTI 유형을 모르시면 '모르겠어요'를 선택해주세요.",
            iDontKnow: "모르겠어요",
            mbtiRequired: "MBTI 유형을 선택해주세요.",
            requiredErrorText: "응답을 선택해주세요.",

            reportIntro: "모든 성격 유형에는 성공적인 비즈니스를 구축하는 데 적합한 고유한 장점이 있습니다. 당신의 강점과 성장 가능성을 함께 살펴보겠습니다.",

            INTP: mbtiFeedback.INTP.ko,
            ENTP: mbtiFeedback.ENTP.ko,
            INFJ: mbtiFeedback.INFJ.ko,
            ENFJ: mbtiFeedback.ENFJ.ko,
            ISTJ: mbtiFeedback.ISTJ.ko,
            ESTJ: mbtiFeedback.ESTJ.ko,
            ISFJ: mbtiFeedback.ISFJ.ko,
            ESFJ: mbtiFeedback.ESFJ.ko,
            INTJ: mbtiFeedback.INTJ.ko,
            ENTJ: mbtiFeedback.ENTJ.ko,
            INFP: mbtiFeedback.INFP.ko,
            ENFP: mbtiFeedback.ENFP.ko,
            ISTP: mbtiFeedback.ISTP.ko,
            ESTP: mbtiFeedback.ESTP.ko,
            ISFP: mbtiFeedback.ISFP.ko,
            ESFP: mbtiFeedback.ESFP.ko,

            q1: personalityQuestions[0].ko,
            q1a1: personalityQuestions[0].options.ko[0],
            q1a2: personalityQuestions[0].options.ko[1],
            q1a3: personalityQuestions[0].options.ko[2],
            q1a4: personalityQuestions[0].options.ko[3],
            q1f1: personalityQuestions[0].feedback.ko[0],
            q1f2: personalityQuestions[0].feedback.ko[1],
            q1f3: personalityQuestions[0].feedback.ko[2],
            q1f4: personalityQuestions[0].feedback.ko[3],
            q2: personalityQuestions[1].ko,
            q2a1: personalityQuestions[1].options.ko[0],
            q2a2: personalityQuestions[1].options.ko[1],
            q2a3: personalityQuestions[1].options.ko[2],
            q2a4: personalityQuestions[1].options.ko[3],
            q2f1: personalityQuestions[1].feedback.ko[0],
            q2f2: personalityQuestions[1].feedback.ko[1],
            q2f3: personalityQuestions[1].feedback.ko[2],
            q2f4: personalityQuestions[1].feedback.ko[3],
            q3: personalityQuestions[2].ko,
            q3a1: personalityQuestions[2].options.ko[0],
            q3a2: personalityQuestions[2].options.ko[1],
            q3a3: personalityQuestions[2].options.ko[2],
            q3a4: personalityQuestions[2].options.ko[3],
            q3f1: personalityQuestions[2].feedback.ko[0],
            q3f2: personalityQuestions[2].feedback.ko[1],
            q3f3: personalityQuestions[2].feedback.ko[2],
            q3f4: personalityQuestions[2].feedback.ko[3],
            q4: personalityQuestions[3].ko,
            q4d: personalityQuestions[3].description.ko,
            q4a1: personalityQuestions[3].options.ko[0],
            q4a2: personalityQuestions[3].options.ko[1],
            q4a3: personalityQuestions[3].options.ko[2],
            q4a4: personalityQuestions[3].options.ko[3],
            q4f1: personalityQuestions[3].feedback.ko[0],
            q4f2: personalityQuestions[3].feedback.ko[1],
            q4f3: personalityQuestions[3].feedback.ko[2],
            q4f4: personalityQuestions[3].feedback.ko[3],
            q5: personalityQuestions[4].ko,
            q5a1: personalityQuestions[4].options.ko[0],
            q5a2: personalityQuestions[4].options.ko[1],
            q5a3: personalityQuestions[4].options.ko[2],
            q5a4: personalityQuestions[4].options.ko[3],
            q5f1: personalityQuestions[4].feedback.ko[0],
            q5f2: personalityQuestions[4].feedback.ko[1],
            q5f3: personalityQuestions[4].feedback.ko[2],
            q5f4: personalityQuestions[4].feedback.ko[3],
            q6: personalityQuestions[5].ko,
            q6a1: personalityQuestions[5].options.ko[0],
            q6a2: personalityQuestions[5].options.ko[1],
            q6a3: personalityQuestions[5].options.ko[2],
            q6a4: personalityQuestions[5].options.ko[3],
            q6f1: personalityQuestions[5].feedback.ko[0],
            q6f2: personalityQuestions[5].feedback.ko[1],
            q6f3: personalityQuestions[5].feedback.ko[2],
            q6f4: personalityQuestions[5].feedback.ko[3],
        },
    }
}