import { VStack } from "@chakra-ui/react"
import { LogoDark } from "../components/logo"
import { PersonalityTest } from "../components/personality/personality-test"

export function PersonalitySurveyPage() {
    return (
        <VStack w={"full"} minH={"100vh"} bg={"bg2.50"} spacing={0}>
            <VStack w={"full"} spacing={0} p={4} pb={{ base: 4 }}>
                <LogoDark />
            </VStack>
            <PersonalityTest />
        </VStack>
    )
}
