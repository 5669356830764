export const strengthsIntros = [
    // pick one of these to use always as the first intro
    // or approve several and randomize them
    {
        "en": "Based on your responses, here are your greatest strengths for starting your side business:",
        "ko": "설문 응답을 바탕으로, 부업을 시작하는 데 있어 가장 큰 강점을 정리해 보았습니다:"
    },
    {
        "en": "Your survey results show that you already have some amazing qualities to build on. Here are your strengths:",
        "ko": "설문 결과, 이미 놀라운 자질을 가지고 계신 것을 알 수 있었습니다. 다음은 당신의 강점입니다:"
    },
    {
        "en": "Every great business starts with great qualities. Here are the strengths you bring to the table:",
        "ko": "모든 훌륭한 비즈니스는 작은 도전에서 시작됩니다. 당신이 가진 강점을 살펴보세요:"
    },
    {
        "en": "Let’s take a look at the traits that will help you succeed on this exciting journey:",
        "ko": "이 비지니스를 성공으로 이끌어줄 당신의 강점을 알아봅시다:"
    },
    {
        "en": "Your answers reveal a strong foundation for success. Here’s what you’re already doing well:",
        "ko": "응답해주신 내용을 보니 비지니스 성공을 위한 강력한 장점을 확인했습니다. 잘하고 계신 부분은 다음과 같습니다:"
    }
]

export const getRandomStrengthsIntro = () => {
    return strengthsIntros[Math.floor(Math.random() * strengthsIntros.length)]
}