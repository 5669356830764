import * as React from "react"
import { useNavigate } from "react-router-dom"

import LocalizedStrings from "react-localization"
import { TypeAnimation } from "react-type-animation"
import { Box, Divider, Heading, VStack, Text } from "@chakra-ui/react"
import { format } from "date-fns"

import { localStorageKey } from "./personality-test"
import { localizedMbtiFeedback as mbtiFeedback } from "./mbti-feedback"

import { getRandomStrengthsIntro } from "./strengths-intro"
import {
    getRandomMotivationIntro,
    localizedMotivationFeedback as motivationFeedback,
} from "./motivation-feedback"
import { getRandomImprovementsIntro } from "./improvements-intro"
import { getRandomPersonalityConclusion } from "./personality-conclusions"
import { localizedFeedbackByQuestionId as feedback } from "./personality-questions"
import { PersonalityRadarGraph } from "./personality-radar-graph"
// import { CallToAction } from "./call-to-action"
import { CallToActionCard } from "./call-to-action-card"

const statusStrings = {
    en: {
        analyzing: "Analyzing your answers...",
        summarizing: "Summarizing your results...",
        generating: "Generating your report...",
    },
    ko: {
        analyzing: "답변을 분석 중입니다...",
        summarizing: "결과를 요약 중입니다...",
        generating: "보고서를 생성 중입니다...",
    },
}

const categories = {
    en: {
        Confidence: "Confidence",
        Resilience: "Resilience",
        Focus: "Focus",
        TimeManagement: "Time Management",
        Motivation: "Motivation",
    },
    ko: {
        Confidence: "자신감",
        Resilience: "회복탄력성",
        Focus: "집중",
        TimeManagement: "시간 관리",
        Motivation: "동기",
    },
}

const maxScores = {
    Confidence: 3,
    Resilience: 3,
    Focus: 9,
    TimeManagement: 3,
    Motivation: 3,
}

const normalizeScores = (scores) => {
    // normalize scores to a 0-100 scale
    // with a minimum of 20 and a maximum of 100
    const normalizedScores = {}
    Object.keys(scores).forEach((key) => {
        normalizedScores[key] = Math.max(
            20,
            Math.min(20 + (scores[key] / maxScores[key]) * 80, 100),
        )
    })
    return normalizedScores
}

export function PersonalityReport() {
    const navigate = useNavigate()
    const [results, setResults] = React.useState(null)
    const [status, setStatus] = React.useState("")
    const [finalScores, setFinalScores] = React.useState({
        Confidence: 0,
        Resilience: 0,
        Focus: 0,
        TimeManagement: 0,
        Motivation: 0,
    })
    const [finalReport, setFinalReport] = React.useState("")
    const [showCallToAction, setShowCallToAction] = React.useState(false)
    const now = React.useRef(format(new Date(), "yyyy.MM.dd HH:mm:ss.SSS"))
    const motivationIntro = React.useMemo(() => getRandomMotivationIntro(), [])
    const strengthsIntro = React.useMemo(() => getRandomStrengthsIntro(), [])
    const improvementsIntro = React.useMemo(
        () => getRandomImprovementsIntro(),
        [],
    )
    const conclusion = React.useMemo(
        () => getRandomPersonalityConclusion().conclusion,
        [],
    )
    const strings = React.useMemo(
        () =>
            new LocalizedStrings({
                en: {
                    title: "Business Personality Profile",
                    intro: "Every personality type has qualities that make it uniquely suited to building a successful business. Let’s explore your strengths and how you can grow to achieve your dreams.",
                    status: statusStrings.en,
                    categories: categories.en,
                    strengthsIntro: strengthsIntro.en,
                    motivationIntro: motivationIntro.en,
                    improvementsIntro: improvementsIntro.en,
                    lookHere: "👇🏻👇🏻👇🏻 Your dream starts here! 👇🏻👇🏻👇🏻",
                },
                ko: {
                    title: "나에게 적합한 부업찾기",
                    intro: "모든 성격 유형에는 성공적인 부업에 적합한 고유한 장점이 있습니다. 당신의 강점과 성장 가능성을 함께 살펴보겠습니다.",
                    status: statusStrings.ko,
                    categories: categories.ko,
                    strengthsIntro: strengthsIntro.ko,
                    motivationIntro: motivationIntro.ko,
                    improvementsIntro: improvementsIntro.ko,
                    lookHere:
                        "👇🏻👇🏻👇🏻 지금 바로 꿈을 현실로 만들어보세요! 👇🏻👇🏻👇🏻",
                },
            }),
        [motivationIntro, strengthsIntro, improvementsIntro],
    )

    const hline = "--------------------------------------------------"

    const calculateScores = (results) => ({
        Confidence: results.q1.reduce((acc, val) => acc + (3 - val), 0),
        Resilience: results.q2.reduce((acc, val) => acc + (3 - val), 0),
        Focus:
            results.q1.reduce((acc, val) => acc + (3 - val), 0) +
            results.q3.reduce((acc, val) => acc + (3 - val), 0) +
            results.q6.reduce((acc, val) => acc + (3 - val), 0),
        TimeManagement: results.q5.reduce((acc, val) => acc + (3 - val), 0),
        Motivation: results.q4.reduce((acc, val) => acc + (3 - val), 0),
    })

    const applyMbtiBonuses = React.useCallback((scores, results) => {
        const scoresWithBonuses = { ...scores }
        if (results.mbti !== "none") {
            const bonuses = {
                I: { key: "Focus", factor: 0.5 },
                E: { key: "Confidence", factor: 0.5 },
                S: { key: "TimeManagement", factor: 0.35 },
                N: { key: "Motivation", factor: 0.5 },
                T: { key: "Resilience", factor: 0.35 },
                F: { key: "Motivation", factor: 0.35 },
                J: { key: "TimeManagement", factor: 0.5 },
                P: { key: "Focus", factor: 0.35 },
            }

            Object.keys(bonuses).forEach((type) => {
                if (results.mbti.includes(type)) {
                    const { key, factor } = bonuses[type]
                    if (scoresWithBonuses[key] < maxScores[key] * .5) {
                        scoresWithBonuses[key] += (maxScores[key] - scores[key]) * factor
                    }
                }
            })
        }
        // console.log("scores", scores)
        // console.log("scoresWithBonuses", scoresWithBonuses)
        return scoresWithBonuses
    }, [])

    const generateReportBuffer = (results, strings, feedback, conclusion) => {
        const reportBuffer = [strings.intro, hline]

        if (results.mbti !== "none") {
            reportBuffer.push(`★★★★★ ${mbtiFeedback[results.mbti]}`)
            reportBuffer.push(hline)
        }

        const strengths = []
        const improvements = []

        // questions with single selection
        // values of 0 and 1 are considered strengths
        // values of 2 and 3 have room for improvement
        const selectOne = ["q1", "q2", "q3", "q5", "q6"]
        selectOne.forEach((key) => {
            results[key].forEach((val) => {
                if (val === 0) {
                    strengths.push(feedback[key][val])
                }
            })
            results[key].forEach((val) => {
                if (val === 1) {
                    strengths.push(feedback[key][val])
                }
            })
            results[key].forEach((val) => {
                if (val === 2) {
                    improvements.push(feedback[key][val])
                }
            })
            results[key].forEach((val) => {
                if (val === 3) {
                    improvements.push(feedback[key][val])
                }
            })
        })
        // if the user demonstrated any strengths
        // add the strength intro and list the strengths
        if (strengths.length > 0) {
            // stars are calculated based on the number of strengths
            // one star for having at least one strength
            // plus one star for each additional strength
            // with a maximum of 5 stars
            // the remaining stars are filled with empty stars
            const numberOfStars = Math.min(strengths.length + 1, 5)
            const numberOfEmptyStars = 5 - numberOfStars
            const stars =
                "★".repeat(numberOfStars) + "☆".repeat(numberOfEmptyStars)
            reportBuffer.push(`${stars} ${strings.strengthsIntro}`)
            strengths.forEach((strength) => {
                reportBuffer.push(`+ ${strength}`)
            })
            reportBuffer.push(hline)
        }

        const sortedMotivations = results.q4.sort((a, b) => a - b).join("_")
        if (sortedMotivations.length > 0) {
            reportBuffer.push(
                `★★★★★ ${strings.motivationIntro} ${motivationFeedback[sortedMotivations]}`,
            )
            reportBuffer.push(hline)
        }

        if (improvements.length > 0) {
            // stars are calculated based on the number of improvements
            // one empty star for every improvement with a maximum of four empty stars (no improvements)
            // a total of 5 stars
            const numberOfEmptyStars = Math.min(improvements.length, 4)
            const numberOfStars = 5 - numberOfEmptyStars
            const stars =
                "★".repeat(numberOfStars) + "☆".repeat(numberOfEmptyStars)
            reportBuffer.push(`${stars} ${strings.improvementsIntro}`)
            improvements.forEach((improvement) => {
                reportBuffer.push(`- ${improvement}`)
            })
            reportBuffer.push(hline)
        }

        reportBuffer.push(conclusion)
        reportBuffer.push(" ")
        reportBuffer.push("---- 꿈코치 (Powered by ChatGPT) ----")
        reportBuffer.push(" ")

        return reportBuffer.join("\n")
    }

    const saveReportStatus = React.useCallback((status) => {
        // save the status of the report to local storage
        localStorage.setItem(localStorageKey, JSON.stringify({
            ...results,
            status,
        }))
    }, [results])

    React.useEffect(() => {
        if (!results) {
            return
        }

        let scores = calculateScores(results)
        scores = applyMbtiBonuses(scores, results)
        scores = normalizeScores(scores)

        const reportBuffer = generateReportBuffer(
            results,
            strings,
            feedback,
            conclusion,
        )

        setFinalScores(scores)
        setFinalReport(reportBuffer)
        saveReportStatus("done")
    }, [results, strings, conclusion, applyMbtiBonuses, saveReportStatus])

    React.useEffect(() => {
        // get personality report data from local storage
        // if not found, redirect to personality survey page
        let parsedResults
        const results = localStorage.getItem(localStorageKey)
        if (!results) {
            navigate("/personality")
            return
        }
        try {
            parsedResults = JSON.parse(results)
            parsedResults.date = new Date(parsedResults.date)
            console.log("parsedResults", parsedResults)
        } catch (error) {
            console.error(error)
            navigate("/personality")
        }
        now.current = format(parsedResults.date, "yyyy.MM.dd HH:mm:ss.SSS")
        setResults(parsedResults)
        setStatus(parsedResults.status)
        // setStatus("analyzing")
    }, [navigate])

    if (!results || status !== "done") {
        return (
            <Box w={"full"} mx={"auto"}>
                <VStack w={"full"} maxW={"xl"} mx={"auto"} px={{ base: 2 }} mb={6}>
                <Box
                    w={"full"}
                    rounded={"md"}
                    bg={"rgb(1, 22, 39)"}
                    my={1}
                    p={0}
                    overflow={"hidden"}
                >
                    <VStack
                        pr={4}
                        pl={5}
                        paddingBlock={5}
                        // pt={14}
                        fontSize={"11pt"}
                        overflowX={"auto"}
                        fontFamily={
                            '"Noto Sans KR", "SF Mono", Menlo, monospace'
                        }
                    >
                        <Box
                            w={"full"}
                            color={"rgb(127, 219, 202)"}
                            whiteSpace={"pre-line"}
                            wordBreak={"keep-all"}
                            minH={"200px"}
                        >
                            <TypeAnimation
                                cursor={false}
                                sequence={[
                                    strings.status.analyzing,
                                    1500,
                                    strings.status.summarizing,
                                    1500,
                                    strings.status.generating,
                                    1000,
                                    () => setStatus("done"),
                                ]}
                                speed={{
                                    type: "keyStrokeDelayInMs",
                                    value: 30,
                                }}
                            />
                        </Box>
                    </VStack>
                </Box>
                </VStack>
            </Box>
        )
    }

    return (
        <Box w={"full"} mx={"auto"}>
            <VStack w={"full"} maxW={"xl"} mx={"auto"} px={{ base: 2 }} mb={12}>

                {finalReport && (
                    <Box w={"full"} mx={"auto"}>
                        <Box
                            w={"full"}
                            rounded={"md"}
                            bg={"rgb(1, 22, 39)"}
                            my={1}
                            p={0}
                            overflow={"hidden"}
                        >
                            <VStack
                                pr={4}
                                pl={5}
                                paddingBlock={5}
                                // pt={14}
                                fontSize={"11pt"}
                                overflowX={"auto"}
                                fontFamily={
                                    '"Noto Sans KR", "SF Mono", Menlo, monospace'
                                }
                            >
                                <VStack spacing={1}>
                                    <Heading
                                        as={"h2"}
                                        fontSize={"13pt"}
                                        color={"white"}
                                    >
                                        <TypeAnimation
                                            cursor={false}
                                            sequence={[strings.title]}
                                            speed={{
                                                type: "keyStrokeDelayInMs",
                                                value: 30,
                                            }}
                                        />
                                    </Heading>
                                    <Text
                                        fontFamily={"SF Mono, Menlo, monospace"}
                                        color={"white"}
                                        fontSize={"8pt"}
                                    >
                                        <TypeAnimation
                                            cursor={false}
                                            sequence={[now.current]}
                                            speed={{
                                                type: "keyStrokeDelayInMs",
                                                value: 30,
                                            }}
                                        />
                                    </Text>
                                </VStack>

                                <Divider
                                    colorScheme={"cyan"}
                                    variant={"dashed"}
                                />
                                <Box w={"full"} mx={"auto"} minH={"150px"}>
                                    <PersonalityRadarGraph
                                        labels={[
                                            strings.categories.Confidence,
                                            strings.categories.Resilience,
                                            strings.categories.Focus,
                                            strings.categories.TimeManagement,
                                            strings.categories.Motivation,
                                        ]}
                                        scores={Object.values(finalScores)}
                                    />
                                </Box>
                                <Divider
                                    colorScheme={"cyan"}
                                    variant={"dashed"}
                                />
                                <Box
                                    w={"full"}
                                    color={"rgb(127, 219, 202)"}
                                    whiteSpace={"pre-line"}
                                    wordBreak={"keep-all"}
                                    minH={"200px"}
                                >
                                    <TypeAnimation
                                        splitter={(str) => str.split(/(?= )/)}
                                        sequence={[
                                            finalReport,
                                            () => setShowCallToAction(true),
                                        ]}
                                        speed={{
                                            type: "keyStrokeDelayInMs",
                                            value: 30,
                                        }}
                                        cursor={false}
                                        omitDeletionAnimation
                                    />
                                    {showCallToAction && (
                                        <Box w={"full"} textAlign={"center"} mt={3}>
                                            {strings.lookHere}
                                        </Box>
                                    )}
                                </Box>
                            </VStack>
                        </Box>
                        {showCallToAction && <CallToActionCard results={results}/>}
                    </Box>
                )}
            </VStack>
        </Box>
    )
}
