import LocalizedStrings from "react-localization"


export const personalityQuestions = [
    {
        id: "q1",
        en: "How do you feel about getting things done?",
        ko: "일을 마칠 때 보통 어떤 기분이 드시나요?",
        description: {
            en: "Select the most appropriate one",
            ko: "가장 적절한 것을 선택해주세요",
        },
        category: "Confidence",
        options: {
            en: [
                "I love the feeling of checking things off my list—it’s the best!",
                "I try my best, but sometimes I get distracted.",
                "I’ll get to it... eventually.",
                "Honestly, I start strong, but finishing is tough.",
            ],
            ko: [
                "할 일을 하나씩 끝낼 때 정말 뿌듯해요!",
                "최선을 다하지만 가끔 산만해질 때가 있어요.",
                "언젠가 하겠죠... 느긋하게요.",
                "솔직히 시작은 잘하는데, 끝내는 게 어려워요.",
            ],
        },
        feedback: {
            en: [
                "You’re already confident in your ability to get things done. Keep it up!",
                "You’re doing well, and a bit more focus could help you achieve even more.",
                "Sometimes we just need a little push to turn plans into actions.",
                "Maybe all you need is a change in your life to build habits that help you finish strong.",
            ],
            ko: [
                "이미 할 일을 잘 해내고 계시네요. 계속 그렇게 해보세요!",
                "잘하고 계세요. 조금 더 집중하면 더 많은 걸 이루실 수 있을 거예요.",
                "작은 변화가 당신을 끝까지 해낼 수 있게 도와줄지도 몰라요.",
                "새로운 변화를 통해 더 나은 습관을 만들어보세요.",
            ],
        },
    },
    {
        id: "q2",
        en: "What do you do when something feels difficult?",
        ko: "어려운 일이 생기면 어떻게 하시나요?",
        category: "Resilience",
        options: {
            en: [
                "I dive in and figure it out as I go!",
                "I take a deep breath and try again.",
                "I look for help or advice—why struggle alone?",
                "I might avoid it for a bit... or a while.",
            ],
            ko: [
                "일단 해보면서 방법을 찾아요!",
                "한숨 돌리고 다시 시도해요.",
                "도움이나 조언을 구해요—혼자 고민할 필요 없잖아요?",
                "일단 미루고 나중에 다시 생각해볼 때가 많아요.",
            ],
        },
        feedback: {
            en: [
                "You’re resilient and handle challenges head-on—great strength!",
                "You’re determined, which is key to overcoming obstacles.",
                "Asking for help is a sign of wisdom and self-awareness.",
                "Maybe all you need is a fresh perspective to approach tough situations.",
            ],
            ko: [
                "당신은 도전 과제를 정면으로 다루는 회복력이 있습니다. 멋진 강점이에요!",
                "끝까지 포기하지 않는 점이 큰 장점이에요.",
                "도움을 구하는 것은 지혜로운 선택이에요.",
                "어려움을 새로운 기회로 바라볼 수도 있어요.",
            ],
        },
    },
    {
        id: "q3",
        en: "When you sit down to work, what usually happens?",
        ko: "일을 시작할 때 보통 어떤 식으로 진행하시나요?",
        category: "Focus",
        options: {
            en: [
                "I get to work and stay focused—let’s go!",
                "I work for a while, but I need a lot of breaks.",
                "I start, but then... Oh look! A text message!",
                "I can’t lie—I procrastinate and end up rushing later.",
            ],
            ko: [
                "일을 시작하면 끝까지 집중해서 쭉 진행해요!",
                "조금 일하다가 쉬는 시간을 자주 가져요.",
                "시작은 하지만... 어, 문자 왔네!",
                "일을 시작해도 잠깐 딴생각을 하거나, 카톡을 보게 돼요.",
            ],
        },
        feedback: {
            en: [
                "You have excellent focus—this will help you accomplish a lot.",
                "You’re doing well, and small adjustments can help you work even more efficiently.",
                "We all get distracted sometimes. Building focus is a skill anyone can learn.",
                "Maybe all you need is a bit of structure to bring out your best focus.",
            ],
            ko: [
                "훌륭한 집중력을 가지고 계시네요—이것이 많은 것을 이루는 데 도움이 될 거예요.",
                "잘하고 계세요. 조금씩 더 꾸준히 하려고 노력해보세요.",
                "산만해질 때도 있지만, 누구나 그럴 수 있어요.",
                "작은 계획과 규칙을 만들어보면 집중에 도움이 될 거예요.",
            ],
        },
    },
    {
        id: "q4",
        en: "Why are you thinking about starting a side business?",
        ko: "부업을 시작하려는 이유는 무엇인가요?",
        description: {
            en: "Select all that apply",
            ko: "해당하는 모든 것을 선택해주세요 (복수선택)",
        },
        category: "Motivation",
        options: {
            en: [
                "I want to earn more money to help my family.",
                "I want to feel proud of building something for myself.",
                "I’m looking for a way to use my free time better.",
                "I need a challenge and something new in my life.",
            ],
            ko: [
                "가족을 돕기 위해 더 많은 돈을 벌고 싶어요.",
                "내 힘으로 무언가를 이루고 뿌듯함을 느끼고 싶어요.",
                "자유 시간을 더 잘 활용할 방법을 찾고 있어요.",
                "새로운 도전과 변화가 필요해요.",
            ],
        },
        feedback: {
            en: [
                "Your motivation to support your family is inspiring—keep going!",
                "Building something for yourself shows great determination and self-worth.",
                "Using free time wisely is a great first step toward success.",
                "A fresh challenge can lead to exciting growth and opportunities.",
            ],
            ko: [
                "가족을 돕고자 하는 동기가 정말 멋져요—계속 나아가세요!",
                "스스로 무언가를 만들고자 하는 의지가 대단해요.",
                "자유 시간을 현명하게 사용하는 것은 성공으로 가는 훌륭한 첫걸음이에요.",
                "새로운 도전은 흥미로운 성장과 기회를 가져다줄 거예요.",
            ],
        },
    },
    {
        id: "q5",
        en: "What do you usually do when you have free time?",
        ko: "자유 시간이 있을 때 보통 무엇을 하시나요?",
        category: "Time Management",
        options: {
            en: [
                "I plan things I want to do and get them done!",
                "I try to do something useful, but Netflix is tempting.",
                "I scroll on my phone until I realize it’s bedtime.",
                "What free time? I barely have any!",
            ],
            ko: [
                "하고 싶은 일을 계획하고 실행해요!",
                "뭔가 유익한 일을 해보려 하지만, 결국 넷플릭스를 보게 돼요.",
                "핸드폰을 계속 보다가 어느새 잘 시간이 돼요.",
                "자유 시간이 없는 것처럼 느껴질 때가 많아요.",
            ],
        },
        feedback: {
            en: [
                "Your proactive use of time is a big advantage—great work!",
                "You’re trying to stay productive, and small changes can help even more.",
                "It’s easy to lose time scrolling. A small plan can make a big difference.",
                "Even a little time can add up to progress with the right habits.",
            ],
            ko: [
                "시간을 능동적으로 사용하는 점이 큰 장점이에요—잘하고 계세요!",
                "생산적이려고 노력하시는 모습이 좋습니다. 작은 변화가 더 많은 도움을 줄 수 있어요.",
                "핸드폰 보며 시간을 보내는 건 누구나 겪는 일이에요. 작은 계획만으로도 큰 변화를 만들 수 있어요.",
                "자투리 시간을 잘 활용하면 분명 생산적인 일을 해낼 수 있어요.",
            ],
        },
    },
    {
        id: "q6",
        en: "How do you handle distractions?",
        ko: "집중에 방해되는 일이 생기면 어떻게 하시나요?",
        category: "Focus",
        options: {
            en: [
                "Distractions? I just ignore them like a boss!",
                "I try to stay focused, but it’s a work in progress.",
                "I get distracted, but I come back eventually.",
                "Distractions usually win, but I do my best!",
            ],
            ko: [
                "방해요? 그냥 무시하고 끝까지 해요!",
                "집중하려고 노력하지만 아직 연습 중이에요.",
                "가끔 산만해지지만, 결국 다시 집중하려고 노력해요.",
                "주위에 시선이 자주 휘둘리지만, 그래도 최선을 다하려고 해요.",
            ],
        },
        feedback: {
            en: [
                "Your ability to tune out distractions is impressive—keep it up!",
                "You’re making progress, and consistency will help you even more.",
                "It’s great that you can refocus. Small habits can make it easier over time.",
                "Distractions happen to everyone. Building small routines can help you stay on track.",
            ],
            ko: [
                "방해를 무시하는 능력이 정말 인상적이에요—계속 그렇게 하세요!",
                "진전을 이루고 계세요. 꾸준함이 더 큰 도움이 될 거예요.",
                "다시 집중할 수 있는 점이 좋아요. 작은 습관이 모여 더 집중하기 쉽게 만들어줄 거예요.",
                "누구나 집중이 흐트러질 수 있어요. 작은 루틴을 만들면 집중력을 유지하는 데 도움이 될 거예요.",
            ],
        },
    },
]

export const localizedFeedbackByQuestionId = new LocalizedStrings(personalityQuestions.reduce((acc, q) => {
    acc.en[q.id] = q.feedback.en.reduce((acc, feedback, i) => {
        acc[i] = feedback
        return acc
    }, {})
    acc.ko[q.id] = q.feedback.ko.reduce((acc, feedback, i) => {
        acc[i] = feedback
        return acc
    }, {})
    return acc
}, {en: {}, ko: {}}))

