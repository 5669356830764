import { useInterval } from '@chakra-ui/react'
import { useState } from 'react'

const getSecondsFromExpiry = (expire) => Math.round((expire - new Date().getTime()) / 1000)

// For more sophisticated timer hooks checkout https://github.com/amrlabib/react-timer-hook
export const useTimer = (props) => {
    const { expiresInSeconds } = props
    // console.log(expiresInSeconds, getSecondsFromExpiry(expiresInSeconds))
    const [seconds, setSeconds] = useState(getSecondsFromExpiry(expiresInSeconds))
    // const [seconds, setSeconds] = useState(expiresInSeconds)

    useInterval(() => setSeconds(getSecondsFromExpiry(expiresInSeconds)), 1000)
    // useInterval(() => setSeconds(expiresInSeconds), 1000)

    return {
        seconds: Math.floor(seconds % 60),
        minutes: Math.floor((seconds % (60 * 60)) / 60),
        hours: Math.floor((seconds % (60 * 60 * 24)) / (60 * 60)),
        days: Math.floor(seconds / (60 * 60 * 24)),
    }
}

