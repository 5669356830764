import { Box, HStack, Text } from "@chakra-ui/react"
import { useTimer } from "./use-timer"

export function Timer({ expiresAt, offerExpiredMessage }) {
    // convert expiresAt to total seconds
    const expiresInSeconds = expiresAt.getTime()
    // console.log("expiresInSeconds", expiresInSeconds)
    const { seconds, minutes /* , hours, days */ } = useTimer({
        expiresInSeconds,
    })
    // console.log(minutes, seconds)

    if (minutes + seconds > 0) {
        return (
            <HStack spacing="4">
                {/*<TimeUnit value={days} label="DAY" />*/}
                {/*<TimeUnit value={hours} label="HRS" />*/}
                <TimeUnit value={minutes} label="MIN" />
                <TimeUnit value={seconds} label="SEC" />
            </HStack>
        )
    }

    return <HStack spacing="4"><Text>{ offerExpiredMessage }</Text></HStack>
}

function TimeUnit(props) {
    const { value, label } = props
    return (
        <Box textAlign="center">
            <Text fontWeight="medium" fontSize="xl" lineHeight="1.2">
                {value.toLocaleString("ko-KR", { minimumIntegerDigits: 2 })}
            </Text>
            <Text
                fontSize="xs"
                fontWeight="semibold"
                color="whiteAlpha.700"
                textTransform="uppercase"
                lineHeight="1rem"
            >
                {label}
            </Text>
        </Box>
    )
}
