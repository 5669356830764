import * as React from "react"
import {
    Box,
    // Button,
    Card,
    CardFooter,
    CardBody,
    // Circle,
    // Center,
    // Container,
    // Flex,
    // Heading,
    HStack,
    Icon,
    // Link,
    // List,
    Text,
    Stack,
    VStack,
} from "@chakra-ui/react"
import { format, addHours } from "date-fns"

import { IoMdStopwatch } from "react-icons/io"
import { getCallToActionMessages } from "./call-to-action-messages"
import { Timer } from "./call-to-action/timer"
import imgs from "../../assets/icons/min"
import { CallToActionButton } from "../call-to-action/call-to-action"
import { kakao } from "../kakao/config"
import { TinyLogoDark } from "../logo"

// import { LuCheck } from "react-icons/lu"

function makeRandomCouponCode(results) {
    // create a random 8 character coupon code
    // if results.mbti is not "none" then use the first 4 characters of results.mbti
    // else generate a random 4 character string
    // the last 4 characters are random characters
    // the alphabet are all uppercase letters and numbers 1-9

    const randomChar = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    const randomNum = "123456789"
    const randomCharacter = () =>
        randomChar[Math.floor(Math.random() * randomChar.length)]
    const randomNumber = () =>
        randomNum[Math.floor(Math.random() * randomNum.length)]
    const randomFour = () =>
        randomCharacter() + randomNumber() + randomCharacter() + randomNumber()
    if (results.mbti !== "none") {
        return `${results.mbti}-${randomFour()}`
    }
    return `${randomFour()}-${randomFour()}`
}

export function CallToActionCard({ results }) {
    const strings = getCallToActionMessages()
    strings.setLanguage("ko")
    // expiresAt is a Date object that is 60 minutes from resultDate (Date object)
    const expiresAt = new Date(results.date.getTime() + 60 * 60 * 1000)
    const chatUrl = kakao.getChatUrl()
    const couponCode = React.useRef(makeRandomCouponCode(results))

    return (
        <VStack w={"full"}>
            <VStack
                w={"full"}
                mx={"auto"}
                bg={"rgb(1, 22, 39)"}
                color="white"
                position="relative"
                maxW="2xl"
                spacing={{ base: "0", md: "4" }}
                rounded={"md"}
            >
                <Box
                    px={{ base: "4", md: "8", lg: "12" }}
                    py={{ base: "3", md: "2.5" }}
                    pr={{ md: "16" }}
                >
                    <Stack
                        direction={{ base: "column", md: "row" }}
                        align="center"
                        justify={{ base: "space-between", lg: "center" }}
                        spacing={{ base: "2", lg: "7.5rem" }}
                    >
                        <HStack>
                            <Icon fontSize={"24px"}>
                                <IoMdStopwatch />
                            </Icon>
                            <Text
                                fontWeight="bold"
                                fontSize="xl"
                                textAlign="center"
                            >
                                LIMITED TIME OFFER
                            </Text>
                        </HStack>

                        <Timer
                            expiresAt={expiresAt}
                            offerExpiredMessage={strings.offerHasExpired}
                        />
                    </Stack>
                </Box>
                <Box w={"full"} p={2}>
                    <Card minH={"200px"} bg={"white"}>
                        <CardBody>
                            <VStack
                                border={"rgb(1, 22, 39) dashed 2px"}
                                p={2}
                                rounded={"md"}
                                spacing={0}
                                textAlign={"center"}
                                position={"relative"}
                            >
                                <Text
                                    bg={"rgb(1, 22, 39)"}
                                    fontSize={"sm"}
                                    color={"rgb(127, 219, 202)"}
                                    fontWeight={"bold"}
                                    textTransform={"uppercase"}
                                    px={1}
                                    mb={4}
                                >
                                    Coupon
                                </Text>
                                <Text fontSize={"md"}>
                                    {strings.screenshot}
                                </Text>
                                <Text
                                    fontWeight="extrabold"
                                    letterSpacing={"tighter"}
                                    fontSize={{ base: "5xl", md: "6xl" }}
                                    textTransform="uppercase"
                                    transform="scale(1.2)"
                                    color={"cute.600"}
                                >
                                    {strings.discount}
                                </Text>
                                <Text fontSize={"md"} pb={4}>
                                    {strings.offDreamCoachPackages}
                                </Text>
                                <Text
                                    bg={"rgb(1, 22, 39)"}
                                    fontSize={"sm"}
                                    color={"rgb(127, 219, 202)"}
                                    fontWeight={"bold"}
                                    textTransform={"uppercase"}
                                    px={1}
                                    mb={4}
                                >
                                    Code
                                </Text>
                                <Text
                                    fontWeight="bold"
                                    letterSpacing={"widest"}
                                    fontSize={{ base: "2xl", md: "3xl" }}
                                    textTransform="uppercase"
                                    transform="scale(1.2)"
                                    color={"black"}
                                    pb={6}
                                >
                                    {couponCode.current}
                                </Text>
                                <Text fontSize={"sm"}>
                                    {format(
                                        addHours(results.date, 1),
                                        "yyyy.MM.dd HH:mm:ss까지",
                                    )}
                                </Text>
                                <TinyLogoDark top={2} left={3} position={"absolute"} w={"40px"} h={"auto"}/>
                            </VStack>

                            <Stack spacing={4}>Text</Stack>
                        </CardBody>
                        <CardFooter>
                            <CallToActionButton
                                img={imgs.kakaoTalk}
                                imgProps={{ alt: "카카오 로고" }}
                                bg={"kakao"}
                                color={"gray.700"}
                                text={"창업무료상담하기"}
                                href={chatUrl}
                                buttonProps={{
                                    className:
                                        "call-to-action-button contact-us contact-us-button",
                                    w: "full",
                                    size: "xl",
                                    h: "3rem",
                                }}
                            />
                        </CardFooter>
                    </Card>
                </Box>
            </VStack>
        </VStack>
    )
}
