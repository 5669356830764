import LocalizedStrings from "react-localization"

export const personalityConclusions = [
    // pick one of these to use always as the first conclusion
    // or approve several and randomize them
    {
        "type": "empowering",
        "en": "Your motivation is your greatest strength, and it’s proof that you’re ready to take on this journey. Combined with the right tools and guidance, your personality is perfectly suited for building something truly special. Let this be the first step toward achieving your dreams.",
        "ko": "당신의 사업을 향한 동기는 가장 큰 강점이며, 이 일을 시작할 준비가 되었음을 증명합니다. 올바른 교육과 가이드만 있으면 당신의 성격은 정말 만족스러운 결과를 만들어낼 수 있습니다. 이것이 당신의 꿈을 이루는 첫 걸음이 되기를 바랍니다."
    },
    {
        "type": "encouraging",
        "en": "Motivation like yours is the foundation for success. You already have the drive and the potential to start your business journey. With focus and the right support, there’s nothing stopping you from achieving your goals.",
        "ko": "당신이 생각하는 이 사업에 대한 강력한 동기는 당신을 성공으로 이끌어 줄 열쇠입니다. 당신은 이미 사업 여정을 시작할 추진력과 잠재력을 가지고 있습니다. 집중력과 교육적인 도움이 있다면 목표를 이루는 데 방해가 될 것이 없어보입니다."
    },
    {
        "type": "supportive",
        "en": "Your motivation stands out as a key strength, and it’s a sign that you’re ready to grow. With your unique personality and determination, you have everything you need to succeed. Take the first step and trust in your ability to make it happen.",
        "ko": "당신의 사업에 대한 동기는 중요한 강점으로 돋보이며, 이는 당신이 성장할 준비가 되었음을 나타냅니다. 당신만의 독특한 성격과 결단력으로 성공에 필요한 모든 것을 갖추고 있습니다. 첫 걸음을 내딛고 당신의 능력을 믿으세요."
    }
]

export const getRandomPersonalityConclusion = () => {
    const randomConclusion = personalityConclusions[Math.floor(Math.random() * personalityConclusions.length)]
    return new LocalizedStrings({
        en: { conclusion: randomConclusion.en },
        ko: { conclusion: randomConclusion.ko },
    })
}