import * as React from "react"
import { Radar } from "react-chartjs-2"
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from "chart.js"

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
)

export function PersonalityRadarGraph({ labels, scores }) {
    const data = {
        labels,
        datasets: [
            {
                // label: "Your Personality Analysis",
                data: scores, // Replace with actual scores
                backgroundColor: "rgba(127, 219, 202, 0.1)", // Subtle overall fill
                borderColor: [
                    "rgba(0, 122, 255, 1)", // Blue for Motivation
                    "rgba(255, 59, 48, 1)", // Pink for Focus
                    "rgba(255, 204, 0, 1)", // Yellow for Resilience
                    "rgba(52, 199, 89, 1)", // Green for Time Mgmt.
                    "rgba(175, 82, 222, 1)"  // Purple for Confidence
                ],
                borderWidth: 2, // Thicker line for impact
                pointBackgroundColor: [
                    "rgba(0, 122, 255, 1)", // Blue
                    "rgba(255, 59, 48, 1)", // Pink
                    "rgba(255, 204, 0, 1)", // Yellow
                    "rgba(52, 199, 89, 1)", // Green
                    "rgba(175, 82, 222, 1)"  // Purple
                ],
                pointBorderColor: "rgba(255, 255, 255, 1)", // White outline for points
                pointHoverBackgroundColor: [
                    "rgba(0, 122, 255, 0.8)", // Slightly dimmed for hover
                    "rgba(255, 59, 48, 0.8)",
                    "rgba(255, 204, 0, 0.8)",
                    "rgba(52, 199, 89, 0.8)",
                    "rgba(175, 82, 222, 0.8)"
                ]
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                angleLines: {
                    color: "rgba(200, 200, 200, 0.2)", // Subtle angle lines
                },
                grid: {
                    color: "rgba(200, 200, 200, 0.2)", // Subtle gridlines
                },
                min: 0, // Set the minimum value
                max: 100, // Set the maximum value
                ticks: {

                    stepSize: 25, // Optional: Add steps between ticks
                    display: false, // Hide numeric ticks for cleaner appearance
                },
                pointLabels: {
                    font: {
                        size: 12, // Adjust font size for mobile readability
                    },
                    color: "rgba(137, 207, 240, 1)", // Same color as text for consistency
                },
            },
        },
        plugins: {
            legend: {
                display: false, // No legend for minimal look
            },
            tooltip: {
                enabled: false, // Disable tooltips since hover isn’t relevant on mobile
            },
        },
        animation: {
            duration: 2000, // Stream-in animation
            easing: "easeOutQuint", // Smooth loading effect
        },
    }

    return <Radar data={data} options={options} />
}
