import LocalizedStrings from "react-localization"

import { personalityQuestions } from "./personality-questions"

const motivationQuestion = personalityQuestions.find((q) => q.id === "q4")

export const motivationIntros = [
    // pick one of these to use always as the first intro
    // or approve several and randomize them
    {
        en: "You scored particularly high in one of the most critical traits for success: motivation.",
        ko: "성공의 핵심 요소 중 하나인 동기 부여에서 높은 점수를 기록하셨습니다.",
    },
    {
        en: "Your motivation is a standout trait, and it’s a key driver for building a successful side business.",
        ko: "당신의 비지니스를 위한 동기를 본받고 싶어요! 이는 눈에 띄는 강점이며, 성공적인 부업을 구축하는 데 중요한 원동력이 됩니다.",
    },
    {
        en: "One of your greatest strengths is your motivation, which lays the foundation for success.",
        ko: "가장 큰 강점 중 하나는 바로 동기 부여로, 성공의 기초를 다지는 요소입니다.",
    },
    {
        en: "Motivation is at the heart of success, and your score shows it’s a key strength for you.",
        ko: "동기부여는 성공의 열쇠예요! 동기부여에서 높은 점수를 받으신 것은 아주 좋은 신호예요.",
    },
    {
        en: "You’ve demonstrated exceptional motivation, a vital quality for turning dreams into reality.",
        ko: "꿈을 현실로 바꾸는 데 필수적인 동기부여에서 우수한 결과가 나왔어요!",
    },
]

export const getRandomMotivationIntro = () => {
    return motivationIntros[Math.floor(Math.random() * motivationIntros.length)]
}

export const motivationFeedback = {
    0: {
        en: motivationQuestion.feedback.en[0],
        ko: motivationQuestion.feedback.ko[0],
    },
    1: {
        en: motivationQuestion.feedback.en[1],
        ko: motivationQuestion.feedback.ko[1],
    },
    2: {
        en: motivationQuestion.feedback.en[2],
        ko: motivationQuestion.feedback.ko[2],
    },
    3: {
        en: motivationQuestion.feedback.en[3],
        ko: motivationQuestion.feedback.ko[3],
    },
    "0_1": {
        en: "Your focus on supporting your family and building something meaningful shows great responsibility and self-determination.",
        ko: "가족을 위해 노력하고, 나만의 가치를 만들어가려는 당신의 열정은 진정한 책임감과 자기 결단력을 보여줍니다.",
    },
    "0_2": {
        en: "Supporting your family while using your free time productively highlights your commitment to making the most of your opportunities.",
        ko: "가족을 돕는 동시에 소중한 시간을 알차게 활용하려는 모습은 당신의 헌신과 실천력을 잘 보여줍니다.",
    },
    "0_3": {
        en: "Your desire to support your family and take on new challenges shows both responsibility and ambition—an excellent combination for success.",
        ko: "가족을 돕고 새로운 도전을 하고자 하는 열망은 책임감과 야망을 모두 보여줍니다. 이는 성공을 위한 훌륭한 조합입니다.",
    },
    "1_2": {
        en: "Building something meaningful for yourself and using your free time wisely demonstrates self-discipline and focus.",
        ko: "자신만의 의미 있는 무언가를 만들고 자유 시간을 현명하게 사용하는 것은 자기 절제와 집중력을 보여줍니다.",
    },
    "1_3": {
        en: "Combining your desire to build something for yourself with a drive for new challenges shows both vision and courage.",
        ko: "자신만의 무언가를 만들고자 하는 열망과 새로운 도전에 대한 의지를 결합한 것은 비전과 용기를 보여줍니다.",
    },
    "2_3": {
        en: "Your focus on using free time productively and seeking new challenges shows initiative and a growth mindset.",
        ko: "자유 시간을 생산적으로 사용하고 새로운 도전을 추구하는 것은 주도성과 성장 마인드를 보여줍니다.",
    },
    "0_1_2": {
        en: "Supporting your family, building something meaningful, and using your free time wisely demonstrates a balanced and goal-oriented mindset.",
        ko: "가족을 돕고 의미 있는 무언가를 만들며 자유 시간을 현명하게 사용하는 것은 균형 잡히고 목표 지향적인 사고방식을 보여줍니다.",
    },
    "0_1_3": {
        en: "Your focus on family, personal growth, and new challenges shows you’re ready to take on responsibilities and grow.",
        ko: "가족, 개인 성장, 새로운 도전에 집중하는 것은 당신이 책임을 맡고 성장할 준비가 되었음을 보여줍니다.",
    },
    "0_2_3": {
        en: "Supporting your family, using your free time productively, and seeking challenges shows a combination of care, discipline, and ambition.",
        ko: "가족을 돕고 자유 시간을 생산적으로 사용하며 도전을 추구하는 것은 배려, 절제, 야망의 조합을 보여줍니다.",
    },
    "1_2_3": {
        en: "Building something for yourself, using your free time productively, and seeking challenges highlights your self-determination and drive to grow.",
        ko: "자신만의 무언가를 만들고 자유 시간을 생산적으로 사용하며 도전을 추구하는 것은 자기 결단력과 성장하려는 의지를 보여줍니다.",
    },
    "0_1_2_3": {
        en: "Your desire to support your family, build something meaningful, use free time wisely, and take on challenges shows a well-rounded and ambitious mindset—perfect for starting your journey.",
        ko: "가족을 돕고, 가치 있는 무언가를 만들어가며, 시간을 알뜰히 사용하고, 새로운 도전에 나서려는 당신의 열정은 균형 잡힌 야망을 보여줍니다. 이는 성공적인 여정의 훌륭한 출발점입니다.",
    },
}

// localize the strings of each value in motivationFeedback

export const localizedMotivationFeedback = new LocalizedStrings(Object.keys(motivationFeedback).reduce((acc, key) => {
    acc.en[key] = motivationFeedback[key].en
    acc.ko[key] = motivationFeedback[key].ko
    return acc
}, { en: {}, ko: {} }))
