export const improvementsIntros = [
    // pick one of these to use always as the first intro
    // or approve several and randomize them
    {
        en: "Every journey has room for growth—these areas can be opportunities to build even stronger habits and skills.",
        ko: "누구에게나 성장할 여지가 있어요! 이 영역들은 더 좋은 습관과 비지니스 능력을 쌓을 수 있는 좋은 기회예요",
    },
    {
        en: "These areas highlight where you have the most potential for growth and improvement.",
        ko: "이 영역들은 당신이 성장하고 개선할 수 있는 잠재력이 가장 큰 부분을 보여줍니다.",
    },
    {
        en: "Recognizing these areas means you’re self-aware and ready to take steps to improve.",
        ko: "이 부분을 인식한다는 것은 당신이 자기 인식이 뛰어나며, 개선을 위한 준비가 되어 있음을 의미합니다.",
    },
    {
        en: "These challenges are opportunities in disguise—they show you where small changes can make a big difference.",
        ko: "이러한 도전은 변화의 기회입니다. 작은 변화가 큰 차이를 만들 수 있는 부분을 보여줍니다.",
    },
    {
        en: "Focusing on these areas can help you unlock new strengths and achieve your goals more effectively.",
        ko: "이러한 영역에 집중하면 새로운 장점을 찾고 목표를 더 효과적으로 달성할 수 있습니다.",
    },
    {
        en: "Growth comes from understanding where you can improve—these areas are the next steps on your path to success.",
        ko: "성장은 개선할 수 있는 부분을 스스로 인정하고 이해하는 데서 시작됩니다. 이러한 부분을 노력하면 성공의 지름길로 갈 수 있어요.",
    },
]

export const getRandomImprovementsIntro = () => {
    return improvementsIntros[Math.floor(Math.random() * improvementsIntros.length)]
}
