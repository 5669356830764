import * as React from "react"

import { useNavigate } from "react-router-dom"
import { Box } from "@chakra-ui/react"
import { Model } from "survey-core"
import { Survey } from "survey-react-ui"
import LocalizedStrings from "react-localization"

import { getPersonalityStrings } from "./personality-strings"

import surveyTheme from "./survey_theme.json"
import "survey-core/defaultV2.min.css"

export const localStorageKey = "personalityTestResults"


export function PersonalityTest() {
    const navigate = useNavigate()
    const [previousResultsLoaded, setPreviousResultsLoaded] = React.useState(false)

    React.useEffect(() => {
        // get previous results, if any, from local storage
        // use date-fns to check if the results are older than 60 minutes
        // if so, remove them
        // if not, redirect to the results page
        const results = localStorage.getItem(localStorageKey)
        if (results) {
            const { date } = JSON.parse(results)
            // if no date, remove the results
            if (!date) {
                localStorage.removeItem(localStorageKey)
                setPreviousResultsLoaded(true)
                return
            }

            const now = new Date()
            if (now - new Date(date) < 60 * 60 * 1000) { // if this is less than 60 minutes old
                navigate("/personality/report")
            } else {
                localStorage.removeItem(localStorageKey)
                setPreviousResultsLoaded(true)
            }
        }
        setPreviousResultsLoaded(true)
    }, [navigate])

    const personalityStrings = getPersonalityStrings()
    const strings = new LocalizedStrings(personalityStrings)
    strings.setLanguage("ko")

    const surveyJson = {
        showQuestionNumbers: false,
        showProgressBar: "top",
        showCompletedPage: false,
        pageNextText: strings.next,
        pagePrevText: strings.previous,
        completeText: strings.complete,
        pages: [
            {
                elements: [
                    {
                        type: "dropdown",
                        name: "mbti",
                        showNoneItem: true,
                        noneText: strings.iDontKnow,
                        noneItem: { value: "", text: strings.iDontKnow },
                        title: strings.mbti,
                        description: strings.mbtiDescription,
                        isRequired: true,

                        requiredErrorText: strings.mbtiRequired,
                        choices: [
                            "ISTJ",
                            "ISFJ",
                            "INFJ",
                            "INTJ",
                            "ISTP",
                            "ISFP",
                            "INFP",
                            "INTP",
                            "ESTP",
                            "ESFP",
                            "ENFP",
                            "ENTP",
                            "ESTJ",
                            "ESFJ",
                            "ENFJ",
                            "ENTJ",
                        ],
                    },
                ],
            },
            {
                elements: [
                    {
                        name: "q1",
                        type: "checkbox",
                        title: strings.q1,
                        isRequired: true,
                        requiredErrorText: strings.requiredErrorText,
                        choices: [
                            {
                                value: 0,
                                text: strings.q1a1,
                            },
                            {
                                value: 1,
                                text: strings.q1a2,
                            },
                            {
                                value: 2,
                                text: strings.q1a3,
                            },
                            {
                                value: 3,
                                text: strings.q1a4,
                            },
                        ],
                    },
                ],
            },
            {
                elements: [
                    {
                        name: "q2",
                        type: "checkbox",
                        title: strings.q2,
                        isRequired: true,
                        requiredErrorText: strings.requiredErrorText,
                        choices: [
                            {
                                value: 0,
                                text: strings.q2a1,
                            },
                            {
                                value: 1,
                                text: strings.q2a2,
                            },
                            {
                                value: 2,
                                text: strings.q2a3,
                            },
                            {
                                value: 3,
                                text: strings.q2a4,
                            },
                        ],
                    },
                ],
            },
            {
                elements: [
                    {
                        name: "q3",
                        type: "checkbox",
                        title: strings.q3,
                        isRequired: true,
                        requiredErrorText: strings.requiredErrorText,
                        choices: [
                            {
                                value: 0,
                                text: strings.q3a1,
                            },
                            {
                                value: 1,
                                text: strings.q3a2,
                            },
                            {
                                value: 2,
                                text: strings.q3a3,
                            },
                            {
                                value: 3,
                                text: strings.q3a4,
                            },
                        ],
                    },
                ],
            },
            {
                elements: [
                    {
                        name: "q4",
                        type: "checkbox",
                        title: strings.q4,
                        description: strings.q4d,
                        isRequired: true,
                        requiredErrorText: strings.requiredErrorText,
                        choices: [
                            {
                                value: 0,
                                text: strings.q4a1,
                            },
                            {
                                value: 1,
                                text: strings.q4a2,
                            },
                            {
                                value: 2,
                                text: strings.q4a3,
                            },
                            {
                                value: 3,
                                text: strings.q4a4,
                            },
                        ],
                    },
                ],
            },
            {
                elements: [
                    {
                        name: "q5",
                        type: "checkbox",
                        title: strings.q5,
                        isRequired: true,
                        requiredErrorText: strings.requiredErrorText,
                        choices: [
                            {
                                value: 0,
                                text: strings.q5a1,
                            },
                            {
                                value: 1,
                                text: strings.q5a2,
                            },
                            {
                                value: 2,
                                text: strings.q5a3,
                            },
                            {
                                value: 3,
                                text: strings.q5a4,
                            },
                        ],
                    },
                ],
            },
            {
                elements: [
                    {
                        name: "q6",
                        type: "checkbox",
                        title: strings.q6,
                        isRequired: true,
                        requiredErrorText: strings.requiredErrorText,
                        choices: [
                            {
                                value: 0,
                                text: strings.q6a1,
                            },
                            {
                                value: 1,
                                text: strings.q6a2,
                            },
                            {
                                value: 2,
                                text: strings.q6a3,
                            },
                            {
                                value: 3,
                                text: strings.q6a4,
                            },
                        ],
                    },
                ],
            },
        ],
    }

    const survey = new Model(surveyJson)
    survey.applyTheme(surveyTheme)

    survey.onValueChanging.add((sender, options) => {
        // ensure only one option is selected
        // but allow user to change selection
        if (["q1", "q2", "q3", "q5", "q6"].includes(options.name)) {
            if (options.oldValue) {
                options.value = options.value.filter(
                    (val) => options.oldValue.indexOf(val) === -1,
                )
            }
        }
    })

    survey.onComplete.add((sender/* , options */) => {
        // console.log(sender.data)
        // save the results to local storage
        // and after they are saved, redirect to the results page
        localStorage.setItem(localStorageKey, JSON.stringify({
            ...sender.data,
            date: new Date()
        }))
        navigate("/personality/report")
    })

    if (!previousResultsLoaded) {
        return null
    }

    return (
        <Box
            className={"personality-container"}
            w={"full"}
            sx={{
                ".sd-btn.sd-navigation__next-btn": {
                    color: "white",
                    backgroundColor: "#d17b84 !important",
                },
                ".sd-item__decorator.sd-checkbox__decorator": {
                    backgroundColor: "bg2.50",
                },
                ".sd-item__control-label span": {
                    fontSize: "14px !important",
                    lineHeight: "20px"
                },
                ".sd-selectbase__label": {
                    alignItems: "center",
                },
                ".sd-item__control-label": {
                    pl: 2,
                },
                ".sd-checkbox--checked use": {
                    fill: "cute.400 !important",
                },
            }}
        >
            <Survey model={survey} />
        </Box>
    )
}
