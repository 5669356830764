import LocalizedStrings from "react-localization"

export const mbtiFeedback = {
    INTP: {
        en: "Your thoughtful and analytical nature as an INTP can be a major advantage in building a side business. Introverts often excel at deep focus and creative problem-solving, which are critical to success.",
        ko: "INTP로서의 신중하고 분석적인 성격은 부업을 구축하는 데 큰 장점이 될 수 있습니다. 내향적인 사람들은 깊은 집중력과 창의적인 문제 해결 능력에서 두각을 나타내며 이는 성공의 핵심입니다.",
    },
    ENTP: {
        en: "Your curiosity and ability to see possibilities as an ENTP are perfect for exploring innovative ideas and starting a unique business. Your adaptability and enthusiasm make you a natural entrepreneur.",
        ko: "ENTP로서의 호기심과 가능성을 보는 능력은 혁신적인 아이디어를 탐구하고 독창적인 사업을 시작하는 데 이상적입니다. 적응력과 열정은 당신을 타고난 기업가로 만들어줍니다.",
    },
    INFJ: {
        en: "Your visionary and empathetic personality as an INFJ makes you a great fit for creating meaningful connections with clients and building a purpose-driven business.",
        ko: "INFJ로서의 비전과 공감 능력은 고객과 의미 있는 관계를 형성하고 목적 중심의 사업을 구축하는 데 적합합니다.",
    },
    ENFJ: {
        en: "Your charismatic and people-focused nature as an ENFJ is a huge strength for building relationships and inspiring others to support your business goals.",
        ko: "ENFJ로서의 매력적이고 사람 중심적인 성격은 관계를 구축하고 다른 사람들이 당신의 사업 목표를 지지하도록 영감을 주는 데 큰 강점이 됩니다.",
    },
    ISTJ: {
        en: "As an ISTJ, your reliability and organizational skills are invaluable for creating a structured and sustainable business. Your methodical approach ensures consistent progress.",
        ko: "ISTJ로서의 신뢰성과 조직 능력은 체계적이고 지속 가능한 사업을 만드는 데 매우 귀중합니다. 체계적인 접근 방식은 꾸준한 성장을 보장합니다.",
    },
    ESTJ: {
        en: "Your natural leadership and practical mindset as an ESTJ are perfect for running a business efficiently and achieving results. You know how to take charge and get things done.",
        ko: "ESTJ로서의 타고난 리더십과 실용적인 사고방식은 비즈니스를 효율적으로 운영하고 결과를 달성하는 데 완벽합니다. 당신은 주도권을 잡고 일을 끝내는 방법을 잘 알고 있습니다.",
    },
    ISFJ: {
        en: "Your nurturing and detail-oriented nature as an ISFJ makes you excellent at providing quality service and creating a business that truly cares for its clients.",
        ko: "ISFJ로서의 세심하고 배려 깊은 성격은 고품질의 서비스를 제공하고 고객을 진심으로 생각하는 사업을 만드는 데 탁월합니다.",
    },
    ESFJ: {
        en: "As an ESFJ, your outgoing and empathetic nature will help you build strong relationships with clients and collaborators. Your people skills are a big advantage for business growth.",
        ko: "ESFJ로서의 외향적이고 공감 능력이 뛰어난 성격은 고객 및 협력자와 강한 관계를 구축하는 데 도움이 됩니다. 사람들과의 소통 능력은 비즈니스 성장의 큰 장점입니다.",
    },
    INTJ: {
        en: "As an INTJ, your strategic thinking and long-term planning abilities make you an excellent fit for building a visionary and well-structured business.",
        ko: "INTJ로서의 전략적 사고와 장기 계획 능력은 비전을 가지고 체계적인 사업을 구축하는 데 탁월합니다.",
    },
    ENTJ: {
        en: "Your natural leadership and drive as an ENTJ make you a powerful force in creating and executing a successful business. Your confidence and vision inspire others to follow your lead.",
        ko: "ENTJ로서의 타고난 리더십과 추진력은 성공적인 비즈니스를 창출하고 실행하는 데 강력한 힘이 됩니다. 당신의 자신감과 비전은 다른 사람들에게 영감을 줍니다.",
    },
    INFP: {
        en: "Your creative and idealistic nature as an INFP helps you connect with others on a meaningful level and create a business that aligns with your personal values.",
        ko: "INFP로서의 창의적이고 이상주의적인 성격은 다른 사람들과 의미 있는 관계를 맺고 자신의 가치관과 일치하는 사업을 만드는 데 도움이 됩니다.",
    },
    ENFP: {
        en: "Your enthusiasm and ability to inspire others as an ENFP make you a natural at building a business filled with innovative ideas and positive energy.",
        ko: "ENFP로서의 열정과 다른 사람들에게 영감을 주는 능력은 혁신적인 아이디어와 긍정적인 에너지로 가득한 사업을 구축하는 데 타고난 자질을 제공합니다.",
    },
    ISTP: {
        en: "Your hands-on and problem-solving mindset as an ISTP makes you great at tackling challenges and finding practical solutions in your business.",
        ko: "ISTP로서의 실용적이고 문제 해결에 강한 사고방식은 비즈니스에서 도전을 해결하고 실용적인 해결책을 찾는 데 탁월합니다.",
    },
    ESTP: {
        en: "As an ESTP, your action-oriented and resourceful personality makes you great at seizing opportunities and turning ideas into reality quickly and effectively.",
        ko: "ESTP로서의 실행 중심적이고 재치 있는 성격은 기회를 포착하고 아이디어를 신속하고 효과적으로 현실화하는 데 뛰어납니다.",
    },
    ISFP: {
        en: "Your creative and hands-on approach as an ISFP helps you craft unique products or services. Your sensitivity to detail is a valuable asset.",
        ko: "ISFP로서의 창의적이고 실천적인 접근 방식은 독창적인 제품이나 서비스를 만드는 데 도움이 됩니다. 세부 사항에 민감한 능력은 귀중한 자산입니다.",
    },
    ESFP: {
        en: "Your outgoing and spontaneous personality as an ESFP makes you great at engaging with clients and bringing vibrant energy to your business.",
        ko: "ESFP로서의 외향적이고 즉흥적인 성격은 고객과 소통하고 사업에 활력을 불어넣는 데 매우 뛰어납니다.",
    },
}

export const localizedMbtiFeedback = new LocalizedStrings(Object.keys(mbtiFeedback).reduce((acc, key) => {
    acc.en[key] = mbtiFeedback[key].en
    acc.ko[key] = mbtiFeedback[key].ko
    return acc
}, { en: {}, ko: {} }))