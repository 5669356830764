import LocalizedStrings from "react-localization"

export const callToActionMessages = {
    title: {
        en: "Limited Time Offer",
        ko: "한정 혜택",
    },
    screenshot: {
        en: "Take a screenshot of this coupon",
        ko: "이 쿠폰을 스크린샷 찍어서",
    },
    discount: {
        en: "50% off",
        ko: "50% off",
    },
    offDreamCoachPackages: {
        en: "and send it to our Kakao channel for a discount on all Dream Coach packages",
        ko: "카카오 채널로 보내시면 모든 꿈코치 패키지에 할인이 적용됩니다",
    },
    offerHasExpired: {
        en: "OFFER HAS EXPIRED",
        ko: "혜택 종료",
    },

    // NOT USED IN THE CURRENT IMPLEMENTATION
    message: {
        en: "Based on your personality, it’s clear that you have the potential to succeed. Your motivation and unique traits are the perfect foundation for building something meaningful.",
        ko: "당신의 성격을 바탕으로 성공할 가능성이 분명히 보입니다. 당신의 동기와 독특한 특성은 의미 있는 무언가를 만들어낼 완벽한 기반입니다.",
    },
    cta1: {
        en: "Are you ready to take the next step? Connect with one of our team members right now to learn how to get started. Be sure to ask about how Dream Coach can help you turn your dreams into reality.",
        ko: "다음 단계로 나아갈 준비가 되셨나요? 지금 바로 저희 팀원과 연결해 시작하는 방법을 알아보세요. 꿈코치가 어떻게 당신의 꿈을 현실로 만드는 데 도움을 줄 수 있는지 꼭 물어보세요.",
    },
    bonus: {
        "en": "Bonus: If you send a screenshot of your business personality report within the next hour (including the timestamp at the top of the report), you qualify for a discount on all Dream Coach packages!",
        "ko": "보너스: 비즈니스 성격 보고서의 스크린샷을 다음 1시간 이내에 보내시면 (보고서 상단의 타임스탬프 포함), 모든 꿈코치 패키지에 대한 할인을 받을 자격이 주어집니다!"
    },
    // must be same as in the bonus message
    bonusTimeLimitHighlight: {
        en: "within the next hour",
        ko: "다음 1시간 이내",
    },
    cta2: {
        en: "Simply click the button below, share your results, and let’s begin this journey together!",
        ko: "아래 버튼을 클릭하고 결과를 공유하면 함께 이 여정을 시작할 수 있습니다!",
    },

}

export const getCallToActionMessages = () => {
    // convert the messages to LocalizedStrings format
    // end result should include { en: { title: title.en }, ko: { title: title.ko } }
    return new LocalizedStrings(Object.keys(callToActionMessages).reduce((acc, key) => {
        acc.en[key] = callToActionMessages[key].en
        acc.ko[key] = callToActionMessages[key].ko
        return acc
    }, { en: {}, ko: {} }))

}